.footer-container {
    display: block;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    border-radius: 8px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: rgb(248, 248, 248);
}

.line-footer{
    width: 100%;
    height: 2px;
    background-color: rgb(111, 184, 159);
    margin-bottom: 30px;
}

.btn-footer {
    padding: 14px;
    width: 45px;
    height: 45px;
    background-color: rgb(111, 184, 159);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    justify-content: center;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-footer:hover {
    background-color: rgb(28, 59, 48);
}

.btn-footer img {
    margin-right: 0;
    width: 25px;
    transform: translateY(0);
}

.images-footer{
    display: flex;
    gap: 20px;
    justify-content: center;

}

.footer-end-text{
    margin-top: 10px;
    display: flex;
    font-family: 'Teko', sans-serif;
    justify-content: center;
    gap: 8px;
}

.footer-end-text img{
    width: 20px;
}

.footer-text{
    font-size: 18px;
    font-weight: 500;
    color: #333;
}



@media screen and (max-width:1150px) {

    .btn-footer {
        padding: 14px;
        width: 50px;
        height: 50px;
    }
    
    .btn-footer img {
        width: 25px;
    }

    .images-footer{
        display: flex;
        gap: 15px;
        justify-content: center;
    
    }

    .line-footer{
        margin-bottom: 35px;
    }

    .footer-end-text{
        margin-top: 20px;
        gap: 8px;
    }
    
    .footer-end-text img{
        width: 18px;
    }
    
    .footer-text{
        font-size: 15px;
        font-weight: 500;
    }
    
}


@media screen and (max-width: 650px) {

    .btn-footer {
        padding: 12px;
        width: 25px;
        height: 25px;
    }
    
    .btn-footer img {
        width: 15px;
    }

    .images-footer{
        display: flex;
        gap: 10px;
        justify-content: center;
    
    }

    .line-footer{
        margin-bottom: 15px;
    }

    .footer-end-text{
        margin-top: 5px;
        gap: 5px;
    }
    
    .footer-end-text img{
        width: 12px;
    }
    
    .footer-text{
        font-size: 10px;
        font-weight: 500;
    }

    .footer-container {
        padding-bottom: 10px;
    }
    
}

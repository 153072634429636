.dropdown-menu {
    display: none;
    flex-direction: column;
    background-color: #ffffff;
    position: absolute;
    top: 90px;
    right: 0;
    padding: 0;
    width: 100vw;
    border: 1px solid #ccc;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    justify-content: center;
    align-items: center;
    border-radius: 0 0 8px 8px;
    transition: all 0.3s ease;
}

.dropdown-menu.show {
    display: flex;
}

.dropdown-menu a {
    text-align: center;
    color: rgb(111, 184, 159);
    font-weight: 500;
    padding: 10px 20px;
    text-decoration: none;
    display: block;
    width: 100%;
    margin: 0;
    transition: background-color 0.2s ease;
    box-sizing: border-box;
}

.dropdown-menu a:hover {
    background-color: #f2f2f2;
}
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgb(111, 184, 159) transparent;
}
.scrollToTopBtn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgb(111, 184, 159);
  border: none;
  border-radius: 50%;
  padding: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
}

.scrollToTopBtn:hover {
  background-color: rgb(28, 59, 48);
}

.scrollToTopBtn .icon {
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 750px) {
  .scrollToTopBtn {
      width: 40px;
      height: 40px;
  }
  
  .scrollToTopBtn .icon {
      width: 20px;
      height: 20px;
  }
  
}
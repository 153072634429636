.container-about {
    margin-top: 75px;
    text-align: center;
    background-color: rgb(111, 184, 159);
}

.intro{
    padding-top: 75px;
    font-size: 2.0rem;
    font-family: 'Arial', sans-serif;
    font-weight: 600;
    color: #fff;
}

.descript{
    padding-top: 10px;
    font-size: 1.25rem;
    font-family: 'Arial', sans-serif;
    margin-left: 575px;
    margin-right: 575px;
    font-weight: 500;
    color: #fff;
    padding-bottom: 150px;
}

@media screen and (max-width: 1750px) {

    .descript {
        margin-left: 400px;
        margin-right: 400px;
    }
    
}

@media screen and (max-width: 1650px) {
    

    .descript {
        margin-left: 390px;
        margin-right: 390px;
    }
    
}

@media screen and (max-width: 1400px) {
    .descript {
        margin-left: 325px;
        margin-right: 325px;
    }
}

@media screen and (max-width: 1250px) {
    
    .descript {
        margin-left: 275px;
        margin-right: 275px;
    }
}

@media screen and (max-width: 1150px) {

    .descript{
        margin-left: 250px;
        margin-right: 250px;
    }
}

@media screen and (max-width: 980px) {
    
    .descript {
        margin-left: 200px;
        margin-right: 200px;
    }

}

@media (max-width: 850px) {
    .intro {
        font-size: 24px;
    }
    
    .descript {
        font-size: 14px;
        margin-left: 175px;
        margin-right: 175px;
    }
}

@media (max-width: 750px) {
    
    .descript {
        margin-left: 125px;
        margin-right: 125px;
    }

}

@media screen and (max-width: 650px) {
    .intro {
        padding-top: 50px;
    }
    
    .descripct {
        margin-left: 85px;
        margin-right: 85px;
    }
}

@media screen and (max-width: 550px) {
    .intro {
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .descript {
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media screen and (max-width: 450px) {
    .intro {
        font-size: 18px;
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .descript {
        padding-left: 25px;
        padding-right: 25px;
    }

}